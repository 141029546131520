<template>
    <div id="footer" class="container-fluid">
        <div class="row">
            <div class="col-6 text-center text-sm-end">
                <p class="footer-text">mjpetour@gmail.com</p>
                <p class="footer-text">+56 9 9319 7262</p>
            </div>
            <div class="col-6 text-center text-sm-start">
                <a href="https://www.linkedin.com/in/mariajesuspetour" target="_blank"><img src="../assets/linkedin.png" alt=""></a>
            </div>  
        </div>
    </div>
</template>

<script>

export default {
name: "FooterPort",
computed: {
    
},
};
</script>

<style>

</style>