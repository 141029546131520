<template>
  <div>
    <a href="https://wa.me/56993197262?" target="_blank"><img src="../assets/icons/whatsapp_icon.png" alt="" class="whatsapp-icon"></a>
  </div>
</template>

<script>

export default{
  name: "WhatsAppBtn",
  props: {

  }

}
</script>
