<template>
  <router-view/>
</template>

<script>

export default {
  name: "App",
  methods: {
  },
}
</script>

<style>

</style>
